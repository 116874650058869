body {
    background: $off-white;
    h1 {
        color: $dark-gray;
        text-align: center;
        font-weight: bold;
        font-size: 3rem;
    }
    h2 {
        color: $dark-gray;
        text-align: center;
        font-family: 'SignPainter', 'Sign Painter', 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        font-weight: 600;
        font-size: 4rem;
        padding: 1rem 0 0;
    }
    p {
        font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        font-size: 1.35rem;
        color: $black;
        line-height: 1.45rem;
        padding: 0;
        @include breakpoint(medium) {
            padding: 0;
        }
    }
}
header {
    p {
        padding: 0;
    }
}
.callout {
    flex-direction: column;
    .image-header {
        display: flex;
        img {
            display: flex;
            padding: 2rem 0;
            margin: 0 auto;
            min-width: 0 !important;
            max-width: 16rem !important;
        }
    }
}
.callout, .pique {
    margin: 0 !important;
    background: $off-white !important;
    border: none !important;
    display: flex;
    @include breakpoint(large) {
        justify-content: center !important;
    }
    img {
        display: flex;
        margin: 0 auto;
        width: 100%;
        @include breakpoint(medium) {
            min-width: 16rem;
        }
        @include breakpoint(large) {
            min-width: 0;
            max-width: 30rem;
        }
    }
}
.pique {
    .copy-general, .copy-piano, .copy-voice {
        p {
            padding: 0 1rem !important;
            @include breakpoint(large) {
                max-width: 30rem;
            }
        }
        display: block;
        @include breakpoint(medium) {
            display: flex;
        }
    }
    .copy-piano {
        background: $black;
        @include breakpoint(large) {
            background: $off-white;
        }
        h2, p {
            color: $off-white;
            @include breakpoint(large) {
                color: $dark-gray;
            }
        }
    }
    .copy {
        padding: 0 0 1.1rem;
        @include breakpoint(medium) {
            padding: 0 .5rem;
        }
    }
}
.introduction, .lessons {
    border-top: solid 1px $dark-gray;
    background: $white;
    padding: 4rem 0;
    h2 {
        color: $black;
    }
    ul {
        margin-top: 0.5rem;
        padding: 0 1rem;
        li {
            font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
            font-size: 1.35rem !important;
            color: $black;
        }
    }
    p {
        margin-top: 0.5rem;
        color: $black;
        padding: 0 1rem;
        a {
            color: $theme;
            font-weight: 600;
            transition: .4s;
            &:hover {
                color: $blue;
            }
        }
        @include breakpoint(medium) {
            padding: 0 .5rem 0 1.5rem;
        }
    }
}
.image-instructor {
    background: $white;
    padding: 0.45rem;
    border: 1px solid $black;
}
.biography {
    border-top: solid 1px $black;
    background: $theme;
    padding: 1rem 0 2rem;
    .biography-center {
        display: flex;
        justify-content: center;
    }
    h2 {
        color: $off-white;
        font-weight: 400;
    }
    p {
        color: $off-white;
        padding: 0 1rem !important;
    }
}
.image-biography-wrapper {
    padding: 2rem 0;
    display: flex;
    background: $black;
    @include breakpoint(large) {
        background: $off-white;
    }
    .image-biography {
        padding: 1rem;
        border: 1px solid $off-white;
        background: $black;
        display: flex;
        margin: 0 auto;
        max-width: 100%;
        object-fit: contain;
        @include breakpoint(large) {
            background: $off-white;
            border: 1px solid $black;
        }
    }
    img {
        display: flex;
        margin: 0 auto;
        max-width: 100%;
        @include breakpoint(medium) {
            max-width: 32rem;
        }
        @include breakpoint(large) {
            width: 100%;
        }
    }
}
footer {
    display: flex;
    padding: 4rem 0 2rem;
    .copyright {
        padding: 3rem 0 0;
        p {
            text-align: center;
            padding: 0 1rem;
            color: $dark-gray;
            font-size: 1rem;
        }
        a {
            color: $theme;
            transition: .4s;
            &:hover {
                color: $blue;
            }
        }
    }

    .image-footer {
        display: flex;
        img {
            display: flex;
            padding: 2rem 0;
            margin: 0 auto;
            min-width: 0 !important;
            max-width: 16rem !important;
        }
    }
}